export const steps = [
  "Select dataset",
  "Model selection",
  "Training",
  "Training result",
];
export const roundDecimalValues = [
1,2,3,4,5,6,7,8,9,10
];

export const defaultPartitionValue = 70;
export const defaultRandomSeedValue = 42;
